import { mapState } from 'vuex';

import {
  // fetchPostAssignStudentsToGroup,
  handleStudentGroupChange,
  postAssignStudentsToGroup,
} from '../../helpers/actionsGroupsOptions';
import { fetchGetGroup } from '../../../shared/helpers/groupOptions';
import { fetchStudentByName } from '../../../students/helpers/utilsStudent';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { getItem } from '../../../../api/api-methods';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { StudentUtils } from '../../../students/mixins/StudentUtilsMixin';
import { fetchGetCriterion } from '../../helpers/criterionOptions';

import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';

export default {
  name: 'KnGroupAddStudent',
  components: {
    KnLocalAlert,
    KnFormActionButtons,
    KnFormTitle,
    KnSelect,
  },
  mixins: [paginationMixin, StudentUtils],
  props: {},
  data() {
    return {
      fullWidth: false,
      mainTitle: 'Administrar grupo',
      group: {},
      showSummaryModal: false,
      studentsSummary: [],
      discounts: [],
      penalties: [],
      groupId: undefined,
      search: '',
      headers: [
        {
          text: 'Alumno',
          value: 'nombre_completo',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      studentsInGroup: [],
      studentsToUpdate: [],
      items: [],
      tab: null,
      tabs: [
        'materias-curriculares',
        'materias-extracurriculares',
        'informacion-adicional',
      ],
      showSubjects: false,
      tuition: [],
      currentStudyPlan: [],
      studysPlan: [],
      loadingCycle: false,
      hasStudyPlan: true,
      hasTuition: true,

      // Alert component
      showAlert: false,
      alertText: '',
      alertType: '',
      alertColor: null,
      loading: false,
      loadingItems: false,
      errors: [],
      loadingPage: false,
    };
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    //#region helpers form methods actions
    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },

    getGroupImage(group) {
      if (!group || !group.imagen_grupo || !group.imagen_grupo.imagen) {
        return require('../../../../assets/images/huellita.png');
      } else {
        return group.imagen_grupo.imagen;
      }
    },

    getSchoolYear(group) {
      if (!group || !group.ciclo_escolar || !group.ciclo_escolar.dato) {
        return 'School year unavailable';
      } else {
        return group.ciclo_escolar.dato;
      }
    },
    toggleSubjects() {
      this.showSubjects = !this.showSubjects;
    },

    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    actionAlertBtn1() {
      console.log('actionAlertBtn1');
      this.closeAlert();
    },
    continueAdding() {
      console.log('continueAdding');
    },
    checkValueChanged(index, value) {
      if (this.previousValue !== value) {
        console.log(index);
      }
      this.previousValue = value;
    },
    returnToTable() {
      this.$router.replace({ name: 'Acciones Grupos' });
    },
    //#endregion
    async emitFilterChange() {
      await this.getStudents(this.search);
    },

    async save() {
      try {
        // Realizar las peticiones en paralelo
        // const [discounts, penalties] = await Promise.all([
        //   getItem(`/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        //   getItem(`/app-administracion/filters/penalizacion?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        // ]);

        // this.discounts = discounts.results;
        // this.penalties = penalties.results;

        const { addedStudents, removedStudents } =
          this.partitionStudentsByChange(this.studentsInGroup);
        const studentsToUpdate = this.studentsToUpdate;

        const studentsSummary = [
          ...addedStudents.map((student) => ({
            ...student,
            generandoAdeudos: true,
            descuento: 0,
            penalizacion: 0,
          })),
          ...removedStudents.map((student) => ({
            ...student,
            generandoAdeudos: false,
            delete: true,
          })),
          ...studentsToUpdate.map((student) => ({
            ...student,
            generandoAdeudos: false,
          })),
        ];

        const uniqueStudentsSummary = studentsSummary.reduce((acc, student) => {
          if (!acc.some((s) => s.id === student.id)) {
            acc.push(student);
          }
          return acc;
        }, []);

        this.studentsSummary = uniqueStudentsSummary;
        this.showSummaryModal = true;
      } catch (error) {
        // Manejar errores
        this.setErrorState(`Ocurrió un error: ${error.message}`);
        console.error('Detailed error:', error);
      }
    },
    async saveWithModifications() {
      try {
        this.setLoadingState(
          true,
          'Guardando modificaciones, por favor, espere...',
          'info'
        );
        const currentGroupId = this.groupId;
        const groupName = this.group.nombre_grupo;

        const removedStudents = this.studentsSummary.filter(
          (student) => !student.generandoAdeudos && student.removed
        );
        const addedStudents = this.studentsSummary.filter(
          (student) => student.generandoAdeudos
        );

        if (removedStudents.length > 0) {
          await Promise.all(
            removedStudents.map(async (student) => {
              this.setLoadingState(
                true,
                `Removiendo al alumno: ${student.nombre_completo}`,
                'info'
              );
              await handleStudentGroupChange(
                student.id,
                currentGroupId,
                '',
                'Desasignación de grupo'
              );
            })
          );
        }

        if (addedStudents.length > 0) {
          this.setLoadingState(
            true,
            `Asignando alumnos y creando materias`,
            'info'
          );
          // const start = this.group.ciclo_escolar.fecha_inicio;
          // const end = this.group.ciclo_escolar.fecha_fin;
          const { ok } = await postAssignStudentsToGroup(
            currentGroupId,
            this.currentStudyPlan.id,
            addedStudents.map(as => as.id)
          );
          if (!ok)
            this.setErrorState(`Ocurrió un error al asignar alumnos al grupo`);
        }

        const messageParts = [];
        if (addedStudents.length > 0) {
          messageParts.push(
            `Se asignaron ${addedStudents.length} ${
              addedStudents.length > 1 ? 'alumnos' : 'alumno'
            }`
          );
        }
        if (removedStudents.length > 0) {
          messageParts.push(
            `Se removieron ${removedStudents.length} ${
              removedStudents.length > 1 ? 'alumnos' : 'alumno'
            }`
          );
        }

        const successMessage = `${messageParts.join(
          ' y '
        )} al grupo ${groupName}.`;
        this.setSuccessState(successMessage);

        this.showSummaryModal = false;
        await this.loadGroup();
        await this.getStudents();
      } catch (error) {
        this.setErrorState(`Ocurrió un error: ${error.message}`);
        console.error('Detailed error:', error);
      }
    },

    partitionStudentsByChange(students) {
      return {
        addedStudents: students.filter((student) => student.recentlyAdded),
        removedStudents: students.filter((student) => student.removed),
      };
    },

    //#region Methods table
    // Métodos para gestionar la adición, eliminación y restauración de estudiantes en un grupo.
    // Se utiliza un flag 'recentlyAdded' para optimizar la eliminación de estudiantes recién agregados
    // y un flag 'removed' para realizar un seguimiento visual del estado del estudiante.
    addStudentToGroup(item) {
      if (!this.studentsInGroup.some((student) => student.id === item.id)) {
        this.$set(item, 'recentlyAdded', true);
        this.$set(item, 'removed', false);
        this.studentsInGroup.push({
          ...item,
          colegiatura: item.colegiatura ? item.colegiatura.id : undefined,
          original_colegiatura: item.colegiatura
            ? item.colegiatura.id
            : undefined,
          __isWatched: false,
        });
      }
    },
    removeStudentFromGroup(index) {
      if (this.studentsInGroup[index].recentlyAdded) {
        this.studentsInGroup.splice(index, 1);
      } else {
        this.$set(this.studentsInGroup[index], 'removed', true);
      }
    },
    restoreStudentToGroup(index) {
      this.$set(this.studentsInGroup[index], 'removed', false);
    },
    handleTuitionChange(student) {
      const hasChanged = student.colegiatura !== student.original_colegiatura;
      if (hasChanged) {
        if (!this.studentsToUpdate.some((s) => s.id === student.id)) {
          this.studentsToUpdate.push(student);
        }
      } else {
        this.studentsToUpdate = this.studentsToUpdate.filter(
          (s) => s.id !== student.id
        );
      }
    },
    //#endregion

    //#region Methods loading form
    async populateStudentInfo(array) {
      try {
        const promises = array.map(async (item) => {
          // const personalData = await getItem(`app-personas/datos-personales/${item.datos_personales}`);
          return {
            ...item,
            nombre_completo: getFullName({ ...item.datos_personales }),
            original_colegiatura: item.colegiatura || undefined,
            colegiatura: item.colegiatura || undefined,
          };
        });

        const populatedArray = await Promise.all(promises);
        array.splice(0, array.length, ...populatedArray);
      } catch (error) {
        console.error('Error populating student info:', error);
      }
    },

    async loadGroup() {
      try {
        const { data } = await fetchGetGroup(this.groupId);
        this.group = data;
        console.log({ data });
        await this.populateStudentInfo(data.alumnos);
        this.studentsInGroup = data.alumnos;
      } catch (error) {
        console.error('Error loading group:', error);
      }
    },

    async loadForm() {
      try {
        await this.getStudents();

        if (!this.group.ciclo_escolar?.id) {
          console.error('El ciclo escolar no está definido.');
          this.setErrorState(
            'Es necesario tener un ciclo escolar asignado para continuar.'
          );
          this.resetFormState();
          return;
        }

        const tuitionRes = await getItem(
          `/app-personas/filters/colegiatura?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        );
        this.tuition = tuitionRes.results;

        if (!this.tuition?.length) {
          console.error('La colegiatura no está asignada.');
          this.resetFormState();
          return;
        }

        this.hasTuition = true;

        const planStudyId = this.group.plan_estudios?.id;

        if (!planStudyId) {
          console.warn(
            'El plan de estudios no está asignado, pero la colegiatura sí.'
          );
          this.hasStudyPlan = false;
          await this.loadStudyPlans();
          return;
        }

        await this.loadStudyPlans();
        this.hasStudyPlan = true;
        await this.getPlanStudy(planStudyId);
      } catch (error) {
        console.error('Error loading form:', error);
        this.setErrorState(`Error al cargar el formulario: ${error.message}`);
        this.resetFormState();
      }
    },

    resetFormState() {
      this.currentStudyPlan = {};
      this.hasStudyPlan = false;
      this.hasTuition = false;
    },

    async loadStudyPlans() {
      const planRes = await getItem(
        `app-personas/filters/plan-estudios?ciclo_escolar=${this.group.ciclo_escolar.id}&institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
      );
      this.studysPlan = planRes.results;
    },

    async constructorFullName() {
      try {
        this.items = this.items.map((e) => ({
          ...e,
          nombre_completo: getFullName(e.datos_personales),
        }));
      } catch (error) {
        console.error('Error constructing full name:', error);
      }
    },
    async getPlanStudy(id) {
      try {
        this.loadingCycle = true;
        const res = this.studysPlan.find((element) => element.id === id);
        if (!res) return;
        await this.populateEvaluationCriteria(res.materias_curriculares);
        await this.populateEvaluationCriteria(res.materias_extracurriculares);
        this.currentStudyPlan = res;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCycle = false;
      }
    },

    async populateEvaluationCriteria(subjectsArr) {
      const promises = subjectsArr.map(async (subject) => {
        const criteriaToFetch = subject.criterios_evaluacion.filter(
          (criterion) =>
            typeof criterion === 'number' ||
            !criterion.nombre ||
            !criterion.porcentaje
        );

        const criteriaPromises = criteriaToFetch.map(async (criterionId) => {
          const { ok, data } = await fetchGetCriterion(criterionId);
          if (!ok)
            throw new Error(`Error fetching criterion with id: ${criterionId}`);
          return data;
        });

        const fetchedCriteria = await Promise.all(criteriaPromises);
        subject.criterios_evaluacion = subject.criterios_evaluacion.map(
          (criterion) => {
            if (typeof criterion === 'number') {
              return (
                fetchedCriteria.find((fetched) => fetched.id === criterion) ||
                criterion
              );
            }
            return criterion;
          }
        );
      });

      await Promise.all(promises);
    },

    async getStudents(search) {
      try {
        this.loadingItems = true;
        let count, data;

        if (search) {
          const response = await fetchStudentByName(
            this.search,
            this.pagination,
            true,
            this.institutionId
          );
          data = response.data;
        } else {
          const limitFilter =
            this.pagination.limit !== null
              ? `&limit=${this.pagination.limit}`
              : '';
          const offsetFilter =
            this.pagination.offset !== 0
              ? `&offset=${this.pagination.offset}`
              : '';
          const tutors = this.tutorId ? `&tutores=${this.tutorId}` : '';
          const url = `/app-personas/filters/alumno?institucion_educativa=${this.institutionId}&sin_grupo=true&estatus_sistema=true${offsetFilter}${limitFilter}${tutors}&grupo=null`;
          const responseData = await getItem(url);
          data = responseData;
        }

        count = data.count;
        this.items = data.results;
        await this.constructorFullName();
        this.setPaginationCount(count);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        this.loadingItems = false;
      }
    },

    //#endregion

    changeStudy(item) {
      this.getPlanStudy(item);
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    invalidFields() {
      const hasStudentsToAdd = this.studentsInGroup.some(
        (student) => student.recentlyAdded
      );
      const hasStudentsToRemove = this.studentsInGroup.some(
        (student) => student.removed
      );
      return hasStudentsToAdd || hasStudentsToRemove;
    },
  },
  watch: {
    studentsInGroup: {
      handler(newVal) {
        newVal.forEach((student) => {
          if (!student.__isWatched) {
            student.__isWatched = true;
            this.$watch(
              () => student.colegiatura,
              (newVal, oldVal) => {
                if (newVal !== oldVal) {
                  this.handleTuitionChange(student, oldVal);
                }
              },
              { immediate: true }
            );
          }
        });
      },
      deep: true,
    },
  },
  async created() {
    this.loadingPage = true;
    this.setPaginationLimit(15);
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      const paramId = this.$route.params.id;
      if (!paramId) return;

      this.groupId = this.validateId(paramId);
      await this.loadGroup();
      await this.loadForm();
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
};
